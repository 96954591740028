
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import AttachedFileViewer from "@/components/attached-file-viewer.vue";
import { getInstaceStateName } from "@/cms-services/helpers";
const visibleProperties = [
  "id",
  "userName",
  "phone",
  "email",
  "url",
  "title",
  "companyName",
  "date",
  "comment",
  "needsConsultation",
  "regionName",

  "folderId",

  "createDate",
  "lastUpdate",
];
@Component({
  components: { AttachedFileViewer },
})
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "ClientCalculation",
  });

  get visibleProperties(): any {
    const properties = this.dataSource.metadata?.properties ?? [];
    const cb = (p) => visibleProperties.some((n) => p.name === n);
    return properties.filter(cb).sort((p, n) => p.priority - n.priority);
  }

  getInstaceStateName: any = getInstaceStateName;

  getInstaceStateColor(instanceStateId) {
    return instanceStateId === 4 ? "success" : "warning";
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Онлайн калькуляции",
        to: "/manage/client-calculations/",
        exact: true,
      },
      {
        text: this.dataSource.model?.id,
        disabled: true,
      },
    ];
  }
}
